import { GetStaticPropsContext } from 'next'

import PgPage from './[[...slug]]'
import { getPageBySlug, getHeaderAndFooter } from '@services/contentful'

export const getStaticProps = async ({ preview }: GetStaticPropsContext<{ slug: [] }>) => {
  const isPreview = preview ?? false

  const page = await getPageBySlug('/404', isPreview)

  if (page) {
    const { footerContentful, headerContentful } = await getHeaderAndFooter(page, isPreview)

    return {
      props: {
        ...page,
        isPreview,
        footer: footerContentful,
        header: headerContentful,
        notFound: false,
        revalidate: 10, // Added the revalidate option to trigger incremental static regeneration.
      },
    }
  }

  return { props: { notFound: true } }
}

export default PgPage
